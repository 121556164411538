import { call, put, select, takeLatest, } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
  getOAuthTokensWithCode,
  loadUserSelf,
  oAuthLogout,
  updateRefkey,
  updateUserSelf
} from "../services/BackendService";
import {
  failureLoadUser,
  failureLogin,
  failureUpdateRefkey,
  LOAD_USER_REQUESTED,
  REQUEST_OAUTH_TOKENS,
  SET_LANGUAGE,
  requestChangeLanguage,
  showErrorToast,
  successLoadUser,
  successLogin,
  successUpdateRefkey,
  UPDATE_REFKEY_REQUEST,
  LOGOUT,
  OAUTH_LOGOUT_REQUESTED,
  logout,
  showGlobalLoader,
} from '../actions';
import { extractErrorMessage } from '../helpers';
import { oAuthClientId, oAuthClientSecret } from '../helpers/oAuthLogin';
import Analytics from '../helpers/analytics';
import { AppState } from "../reducers";

function* requestUpdateRefkey(action: AnyAction) {
  try {
    yield call(updateRefkey, action.refKey);
    yield put(successUpdateRefkey());
  } catch (e) {
    yield put(failureUpdateRefkey(extractErrorMessage(e)));
    yield put(showErrorToast(extractErrorMessage(e)));
  }
}

function* updateUserLanguage(action: AnyAction) {
  try {
    const { language, skipUserUpdate } = action;
    if (!skipUserUpdate) {
      const { user } = yield call(updateUserSelf, { communicationLanguage: language });
      yield put(successLoadUser(user));
    }
  } catch (e) {
    yield put(showErrorToast((e as Error).message));
  }
}

function* requestLoadUser() {
  try {
    const { token } = yield select((s) => s.user);
    const { user } = yield call(loadUserSelf, token);
    yield put(requestChangeLanguage(user.communicationLanguage, true));
    yield put(successLoadUser(user));
    Analytics.identifyUser(user.id);
  } catch (e) {
    yield put(failureLoadUser(extractErrorMessage(e)));
  }
}

function* requestOAuthTokensSaga(action: AnyAction) {
  const {
    code,
  } = action;
  const redirectUri: string = `${window.location.origin}/login`;
  const grantType: string = 'authorization_code';

  try {
    window.history.replaceState({}, document.title, '/');
    const {
      access_token: accessToken,
      refresh_token: refreshToken
    } = yield call(getOAuthTokensWithCode, code, oAuthClientId as string, oAuthClientSecret as string, redirectUri, grantType);
    const {
      user
    } = yield call(loadUserSelf, accessToken);
    yield put(requestChangeLanguage(user.communicationLanguage, true));
    yield put(successLogin(user, accessToken, refreshToken));
    Analytics.identifyUser(user.id);
  } catch (e) {
    yield put(failureLogin(extractErrorMessage(e)));
    yield put(showErrorToast(extractErrorMessage(e)));
  }
}

function* logoutSaga(action: AnyAction) {
  Analytics.resetUserData();
  yield;
}

function* oAuthLogoutSaga(action: AnyAction) {
  const refreshToken: string = yield select((s: AppState) => s.user.refreshToken);
  let redirectUri: string | null = null;
  try {
    redirectUri = yield call(oAuthLogout, refreshToken, `${window.location.origin}/login`);
  } catch (e) {
    console.error('Failed to logout from Cashier', e);
  }

  yield put(logout());
  if (redirectUri !== null) {
    yield put(showGlobalLoader());
    // @ts-expect-error Poorly written window.location type
    window.location = redirectUri;
  }
}

function* userSaga() {
  yield takeLatest(UPDATE_REFKEY_REQUEST, requestUpdateRefkey);
  yield takeLatest(LOAD_USER_REQUESTED, requestLoadUser);
  yield takeLatest(SET_LANGUAGE, updateUserLanguage);
  yield takeLatest(REQUEST_OAUTH_TOKENS, requestOAuthTokensSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(OAUTH_LOGOUT_REQUESTED, oAuthLogoutSaga);
}

export default userSaga;
