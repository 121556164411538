import React from 'react';
import {
  CDataTable,
  CPagination,
} from '@coreui/react';
import _t from 'counterpart';

export interface ISorter {
  column: string;
  asc: boolean;
}

interface IProps {
  onSearchValueChanged?: (value: string) => void;
  onPageChanged?: (page: number) => void;
  onSorterChanged?: (values: ISorter) => void;
  onRowClicked?: (value: any) => void;
  noItemsViewSlot?: any;
  sorter?: ISorter;
  showFilter?: boolean;
  pagination?: boolean;
  activePage?: number;
  search?: string;
  clickableRows?: boolean;
  data: any;
  tableFields: any;
  loading: boolean;
  pages: number;
  scopedSlots: any;
}

const PaginationTable = (props: IProps) => {
  const {
    data,
    pages,
    tableFields,
    pagination,
    loading,
    showFilter,
    activePage,
    search,
    sorter,
    scopedSlots,
    clickableRows,
    noItemsViewSlot,
    onRowClicked,
  } = props;

  const onSorterChange = (values: ISorter) => {
    const { onSorterChanged } = props;
    if (onSorterChanged) {
      onSorterChanged(values);
    }
  };

  const setPage = (page: number) => {
    const { onPageChanged } = props;
    if (onPageChanged) {
      onPageChanged(page);
    }
  };

  const setTableFilterValue = (search: string) => {
    const { onSearchValueChanged } = props;
    if (onSearchValueChanged) {
      onSearchValueChanged(search);
    }
  };

  const { column, asc } = sorter || { column: 'createdAt', asc: true };

  return (
    <>
      <CDataTable
        items={data}
        fields={tableFields}
        tableFilter={showFilter && { external: true, placeholder: _t('global.search-something') }}
        tableFilterValue={search}
        onTableFilterChange={setTableFilterValue}
        hover
        sorter={{ external: true }}
        sorterValue={{ column, asc }}
        onSorterValueChange={onSorterChange}
        loading={loading}
        striped
        scopedSlots={scopedSlots}
        clickableRows={clickableRows}
        onRowClick={clickableRows ? onRowClicked : undefined}
        noItemsViewSlot={noItemsViewSlot}
      />
      {pagination && data && pages > 1
        && (
          <CPagination
            pages={pages}
            activePage={Number(activePage)}
            onActivePageChange={setPage}
            className={pages! < 2 ? 'd-none' : ''}
          />
        )}
    </>
  );
};

PaginationTable.defaultProps = {
  onSearchValueChanged: null,
  onPageChanged: null,
  onSorterChanged: null,
  onRowClicked: null,
  sorter: null,
  noItemsViewSlot: null,
  showFilter: false,
  pagination: false,
  clickableRows: undefined,
  activePage: 0,
  search: '',
};

export default PaginationTable;
