import React from 'react';
import { connect } from 'react-redux';
import {
	CButton,
	CButtonToolbar,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CContainer,
	CForm,
	CFormGroup,
	CInput,
	CLabel,
	CRow,
} from '@coreui/react';
import _t from 'counterpart';
import { AppState } from '../reducers';
import { UserState } from '../reducers/userReducer';
import { requestUpdateRefkey } from '../actions';
import { MainTitle } from '../components/MainTitle/MainTitle';

interface ProfileActions {
	updateRefkey: (refKey: string) => void;
}

interface ProfilePageState {
	newRefKey: string | null;
}

class ProfilePage extends React.Component<{ user: UserState } & ProfileActions, ProfilePageState> {
	constructor(props: any) {
		super(props);

		this.state = {
			newRefKey: null,
		};
	}

	updateReferralCode = () => {
		const { newRefKey } = this.state;
		const { updateRefkey } = this.props;

		if (newRefKey) {
			updateRefkey(newRefKey);
		}
	};

	render() {
		const { user } = this.props;
		const { name, mobilenumber, email, refKey } = user;

		return (
			<CContainer fluid className="c-main main-holder">
				<CRow>
					<CCol>
						<MainTitle>{_t('profile.title')}</MainTitle>
						<p>{_t('profile.description')}</p>
					</CCol>
				</CRow>
				<CRow>
					<CCol md={4}>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('global.details')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<dl>
									<dt>{_t('global.name')}:</dt>
									<dd>{name}</dd>
								</dl>
								<dl>
									<dt>{_t('global.email')}:</dt>
									<dd>{email}</dd>
								</dl>
								<dl>
									<dt>{_t('global.mobile-number')}:</dt>
									<dd>{mobilenumber}</dd>
								</dl>
							</CCardBody>
						</CCard>
					</CCol>
					<CCol md={4}>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('profile.referral-code')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<CForm action="" method="post">
									<dl>
										<dt>{_t('profile.current-referral-code')}</dt>
										<dd>{refKey}</dd>
									</dl>
									<CFormGroup>
										<CLabel htmlFor="nf-referral">{_t('profile.new-referral-code')}</CLabel>
										<CInput
											id="nf-referral"
											placeholder={_t('profile.new-referral-code')}
											onChange={(e: any) => this.setState({ newRefKey: e.target.value })}
											disabled
										/>
									</CFormGroup>

									<CButtonToolbar justify="end">
										<CButton color="primary" onClick={this.updateReferralCode} disabled>
											{_t('action.update')}
										</CButton>
									</CButtonToolbar>
								</CForm>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			</CContainer>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	user: state.user,
});

const mapDispatchToProps = {
	updateRefkey: requestUpdateRefkey,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
