import React, { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './logoUploader.scss';
import { CButton } from '@coreui/react';
import _t from 'counterpart';
import ButtonWithLoader from '../ButtonWithLoader';

interface IProps {
  url: string;
  onSave: (url: string) => void;
  onExit: () => void;
  minWidth: number;
  minHeight: number;
}

const ImageCropper = ({ url, onSave, onExit, minWidth, minHeight }: IProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string | undefined>(undefined);

  const onCroppedImageSaved = async () => {
    setIsLoading(true);
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const dataURL = cropper.getCroppedCanvas().toDataURL();
      onSave(dataURL);
    }
    setIsLoading(false);
  };

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const imgData = cropper.getCroppedCanvas();

      if (imgData.width < minWidth) {
        setErrorString(_t('my-brand.width-too-small'));
      }

      if (imgData.width >= minWidth && imgData.height >= minHeight) {
        setErrorString(undefined);
      }
    }
  };

  return (
    <>
      <Cropper
        crop={onCrop}
        src={url}
        className="logo-cropper"
        autoCropArea={1}
        viewMode={1}
        aspectRatio={minWidth / minHeight}
        movable={false}
        zoomable={false}
        guides={false}
        ref={cropperRef}
        scalable={false}
      />
      <div className="mt-2 d-flex justify-content-between">
        <CButton color="primary" disabled={isLoading} onClick={onExit}>
          {_t('global.cancel')}
        </CButton>
        <ButtonWithLoader
          title={_t('global.save-changes')}
          isLoading={isLoading}
          onClick={onCroppedImageSaved}
          buttonColor="primary"
          disabled={errorString !== undefined}
        />
      </div>
      <span className="error-text">{errorString}</span>
    </>
  );
};

export default ImageCropper;
