import React from 'react';
import { CBadge } from '@coreui/react';
import { MessageType } from '../types';

const MessageTypeBadge = (props: { type: MessageType }) => {
  const color = props.type === MessageType.Push ? 'info' : 'warning';
  return (
    <CBadge color={color}>{props.type}</CBadge>
  );
};

export default MessageTypeBadge;
