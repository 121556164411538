import React, { useState } from 'react';
import {
  CButton,
  CCard, CCardBody,
  CCol, CContainer,
  CInput,
  CRow,
  CToast,
  CToastHeader,
  CToaster,
} from '@coreui/react';
import _t from 'counterpart';
import { MainTitle } from '../components/MainTitle/MainTitle';
import { Callout } from '../components/Callout';
import { useMutation } from 'react-query';
import { generateApiKey } from '../services/BackendService';

const AUTO_HIDE_TOAST = 3000

const ApiKeysPage = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const apiKeyMutation = useMutation(() => generateApiKey(),
    {
      onSuccess: (key: string) => {
        setInputValue(key);
      }
    });

  const invokeMutation = () => {
    apiKeyMutation.mutate();
  };

  const toggleToastStateChange = (state: "hiding" | boolean) => {
    if(state === "hiding" ) return
    setIsCopied(state)
  }

  return (
    <>
    <CToaster position="top-right">
      <CToast
        className={"bg-success"}
        show={isCopied && Boolean(inputValue)}
        autohide={AUTO_HIDE_TOAST}
        onStateChange={toggleToastStateChange}
      >
        <CToastHeader >
          {_t("api-keys.copied")}
        </CToastHeader>
      </CToast>
    </CToaster>

    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>
            {_t('sidebar.api-keys')}
          </MainTitle>
          <Callout
            color="primary"
            label={_t('api-keys.generate-api-key')}
            value={_t('api-keys.description')}
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CCard>
            <CCardBody className="d-flex">
            
              <CInput value={inputValue} className="mr-2" style={{cursor: inputValue ? "copy" : "default"}} readOnly  
                onClick={() => {
                  navigator.clipboard.writeText(inputValue);
                  setIsCopied(true)
                }}
              />
              <CButton
                color="primary"
                onClick={invokeMutation}
              >
                {_t('api-keys.generate')}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
    </>
  );
};

export default ApiKeysPage;
