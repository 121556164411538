import React from 'react';
import {
  CCard, CCardBody,
  CContainer,
  CRow,
  CCol,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react';
import _t from 'counterpart';
import Loading from '../components/Loading';
import { formatCurrency } from '../helpers';
import { useQuery } from 'react-query';
import { loadDownlineStats } from '../services/BackendService';
import { NetworkError } from '../components/NetworkError/NetworkError';
import { MainTitle } from '../components/MainTitle/MainTitle';

const AnalyticsPage = () => {
  const analyticsQuery = useQuery('analytics', loadDownlineStats);

  if (analyticsQuery.isLoading) {
    return <Loading />;
  }

  if (analyticsQuery.isError) {
    return <NetworkError handleRetry={analyticsQuery.refetch} />;
  }

  return (
    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>
            {_t('sidebar.analytics')}
          </MainTitle>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CCard>
            <CCardBody>
              <CTabs activeTab="downline-stats">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink data-tab="downline-stats">
                      {_t('analytics.downlines')}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane data-tab="downline-stats">
                    <div className="table-responsive">
                      <table className="table mt-4">
                        <thead>
                          <tr>
                            <th scope="col">{_t('analytics.level')}</th>
                            <th scope="col">{_t('analytics.registrations')}</th>
                            <th scope="col">{_t('analytics.push-accepted')}</th>
                            <th scope="col">{_t('analytics.app-downloaded')}</th>
                            <th scope="col">{_t('analytics.total-deposits')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {analyticsQuery.data!.map((s) => (
                            <tr key={`stats-${s.level}`}>
                              <td><span className="badge badge-pill badge-primary h6">{s.level}</span></td>
                              <td>{s.registrations}</td>
                              <td>{s.pushNotifications}</td>
                              <td>{s.downloads}</td>
                              <td>{formatCurrency(s.totalDeposits)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CTabPane>
                </CTabContent>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default AnalyticsPage;
