import { put, takeLatest, delay } from 'redux-saga/effects';
import { SHOW_ERROR_TOAST, hideErrorToast, SHOW_SUCCESS_TOAST, hideSuccessToast } from '../actions';

function* autoHideErrorToast() {
  yield delay(5000);
  yield put(hideErrorToast());
}

function* autoHideSuccessToast() {
  yield delay(5000);
  yield put(hideSuccessToast());
}

function* notificationsSaga() {
  yield takeLatest(SHOW_ERROR_TOAST, autoHideErrorToast);
  yield takeLatest(SHOW_SUCCESS_TOAST, autoHideSuccessToast);
}

export default notificationsSaga;
