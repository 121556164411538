import React, { useEffect, useState } from 'react';
import {
	CButton,
	CForm,
	CFormGroup,
	CLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CSelect,
} from '@coreui/react';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { loadCustomerWallets, requestCommissionPayout } from '../services/BackendService';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../actions';
import { extractErrorMessage, formatCurrency } from '../helpers';
import { IWallet } from '../types';

const RequestTransferModal = ({ show, onClose, totalAmount }: any) => {
	const dispatch = useDispatch();
	const [walletId, setWalletId] = useState<string>('');

	const loadCustomerWalletsQuery = useQuery('load-customer-wallets', loadCustomerWallets, {
		onError: (error) => {
			dispatch(showErrorToast(extractErrorMessage(error)));
		},
	});

	useEffect(() => {
		if (loadCustomerWalletsQuery.isSuccess && walletId === '') {
			setWalletId(loadCustomerWalletsQuery?.data[0].id);
		}
	});
	const requestCommissionPayoutMutation = useMutation(
		(payoutWalletId: string) => requestCommissionPayout(payoutWalletId),
		{
			onSuccess: () => {
				dispatch(showSuccessToast(_t('commission.payout-request-success')));
				onClose();
			},
			onError: (error) => {
				dispatch(showErrorToast(extractErrorMessage(error)));
			},
		}
	);

	const invokeRequestCommissionPayoutMutation = (event: React.FormEvent) => {
		event.preventDefault();
		requestCommissionPayoutMutation.mutate(walletId);
	};

	const updateWalletId = (event: any) => {
		const { value } = event.target;
		setWalletId(value);
	};

	return (
		<CModal show={show} onClose={onClose}>
			<CModalHeader closeButton>
				<CModalTitle>{_t('commission.payout-request')}</CModalTitle>
			</CModalHeader>
			<CForm onSubmit={invokeRequestCommissionPayoutMutation}>
				<CModalBody>
					<CLabel>{_t('commission.payout-request-note')}</CLabel>
					<CFormGroup>
						<CLabel htmlFor="nf-walletId">{_t('commission.select-wallet')}</CLabel>
						<CSelect id="nf-walletId" name="nf-walletId" onChange={updateWalletId}>
							{loadCustomerWalletsQuery.data?.map((wallet: IWallet) => {
								return <option key={`wallet-${wallet.id}`} value={wallet.id}>{`${wallet.username}`}</option>;
							})}
						</CSelect>
					</CFormGroup>
					<CLabel>
						{_t('commission.payout-amount')}: {formatCurrency(totalAmount)}
					</CLabel>
				</CModalBody>

				<CModalFooter>
					<CButton type="submit" color="primary">
						{_t('action.confirm')}
					</CButton>
					<CButton type="button" color="secondary" onClick={onClose}>
						{_t('action.cancel')}
					</CButton>
				</CModalFooter>
			</CForm>
		</CModal>
	);
};

export default React.memo(RequestTransferModal);
