import React from 'react';
import { CBadge } from '@coreui/react';
import _t from 'counterpart';

interface IProps {
	approvedAt: Date | null;
	rejectedAt: Date | null;
}

enum StatusType {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	PENDING = 'PENDING',
}

const PayoutStatusBadge = ({ approvedAt, rejectedAt }: IProps) => {
	const colors: { [key in StatusType]: string } = {
		APPROVED: 'success',
		REJECTED: 'danger',
		PENDING: 'warning',
	};

	const translations: { [key in StatusType]: string } = {
		APPROVED: _t('commission.approved'),
		REJECTED: _t('commission.rejected'),
		PENDING: _t('commission.pending'),
	};

	let status = StatusType.PENDING;
	if (approvedAt) {
		status = StatusType.APPROVED;
	}
	if (rejectedAt) {
		status = StatusType.REJECTED;
	}

	return <CBadge color={colors[status]}>{translations[status]}</CBadge>;
};

export default PayoutStatusBadge;
