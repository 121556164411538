import { CButton, CCard, CCardBody, CCol } from "@coreui/react";
import _t from "counterpart";
import React from "react";
import { ILandingPage } from "../types";
import WebtraderPNG from "../images/webtrader.png";
import { useQuery } from "react-query";
import axios from "axios";

const LandingPageCard = ({ landingPage }: { landingPage: ILandingPage }) => {
  const imageQuery = useQuery(
    ["landing-page-preview", landingPage.preview],
    async () => {
      await axios.get(landingPage.preview);
    }
  );

  const { isSuccess } = imageQuery;

  return (
    <CCol xs={12} sm={6} md={4} xl={4} className="d-flex">
      <CCard className="w-100 card card--marketing">
        <div className="card__illustration" />
        <div className="card__holder">
          <div className="wrap">
            {isSuccess && <img src={landingPage.preview} alt="preview" />}
            {!isSuccess && <img src={WebtraderPNG} alt="preview" />}
          </div>
        </div>
        <CCardBody className="card__data">
          <h3>{landingPage.name}</h3>
          <div className="card__languages">
            <p className="title">{_t("global.language")}</p>
            <p className="languages">
              {landingPage.language ?? _t("global.all")}
            </p>
          </div>
          <div className="card__link">
            <div className="link-preview">
              <a href={landingPage.url} target="_blank" rel="noreferrer">
                {landingPage.url}
              </a>
            </div>
            <div className="link-btn">
              <CButton
                color="primary"
                className="btn-copy"
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(landingPage.url);
                }}
              >
                {_t("action.copy")}
              </CButton>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default LandingPageCard;
