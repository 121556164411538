import * as Sentry from '@sentry/react';
import moment from 'moment';
import _t from 'counterpart';
import React from 'react';

export function truncate(text: string, max: number) {
  return text.substr(0, max - 1) + (text.length > max ? '&hellip;' : '');
}

export function formatCurrency(amount: number | string, symbol: string = 'USD', maximumFractionDigits = 2) {
  try {
    const formatter = new Intl.NumberFormat('en-US',
      { style: 'currency', currency: symbol, currencyDisplay: 'symbol', maximumFractionDigits });
    return formatter.format(Number(amount));
  } catch (e) {
    Sentry.captureException(e);
    return `${Number(amount).toFixed(2)} ${symbol}`;
  }
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function firstToLowerCase(text: string) {
  return text.charAt(0).toLocaleLowerCase() + text.slice(1);
}

export function formatDate(date: Date): string {
  return new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date);
}

export function formatDateTime(date: string | Date, formatString: string = 'lll') : string {
  return moment(date).format(formatString);
}

export function extractErrorMessage(error: any) {
  if (!error) {
    return _t('errors.unknown');
  }

  if (error.response?.data?.error) {
    return error.response.data.error.message || error.response.data.error;
  }

  if (error.response?.data?.errors && Array.isArray(error.response.data.errors)) {
    return error.response.data.errors[0].msg;
  }

  return error.message;
}

export function extractValidationErrorMessage(error: any) {
  if (!error?.response?.data?.errors || error?.response?.data?.errors.length < 1) {
    return null;
  }

  return error.response.data.errors;
}

export function errorWithCode(error: any) {
  if (!error) {
    return { message: null, code: 500 };
  }

  if (error.response?.data?.error) {
    const message = error.response.data.error.message || error.response.data.error;
    const code = error.response.status;
    return { message, code };
  }

  if (error.response?.status) {
    return { message: 'Unkown error', code: error.response.status };
  }

  return { message: 'Unkown error', code: 500 };
}

export function extractQueryParams(search: any) {
  const queryParams = new URLSearchParams(search);
  const entries = queryParams.entries();
  const params = { ...Object.fromEntries(entries) };
  return params;
}

export function findErrorFromValidation(validationErrors: any, paramName: string) {
  const errors = extractValidationErrorMessage(validationErrors);
  if (errors && errors.length > 0) {
    const error = errors.find((e: any) => e.param === paramName);
    if (error) {
      return error.msg;
    }
  }
  return null;
}

export function doesIconWithNameExist(iconName: string) {
  // CoreUI's weird sharing of icons
  // @ts-ignore
  return !!React.icons[iconName];
}

export function getIconNameForCountry(country: string) {
  const iconName = `cif${capitalize(country)}`;
  if (doesIconWithNameExist(iconName)) {
    return iconName;
  }
  return null;
}

const countryCodesForLanguages: { [key: string]: string } = {
  en: 'gb',
  ur: 'pk',
  zh: 'cn',
  ja: 'jp',
  hi: 'in',
  ar: 'ae',
  km: 'kh',
  vi: 'vn',
  ms: 'my',
};

export function getIconNameForLanguage(language: string) {
  const countryCode = capitalize(countryCodesForLanguages[language] ?? language);
  return getIconNameForCountry(countryCode);
}

export function parseOrderBy(column: string, asc: boolean) {
  return `${column}|${asc ? 'ASC' : 'DESC'}`;
}

export const coalesce = (...params: Array<any | null>): any | null => {
	const nonNull = params.find((param) => param !== null);
	return nonNull || undefined;
}

export function getFiltersCount(selector: any) {
	const objectKeys = Object.keys(selector);
	let count = Object.values(selector).reduce<number>((count, filterValue, currentIndex) => {
		const filterKey = objectKeys[currentIndex];
		const notRange = filterKey === 'amountFrom' || filterKey === 'amountTo';
		if (
			((filterValue && !Array.isArray(filterValue)) || (Array.isArray(filterValue) && filterValue.length > 0)) &&
			!notRange
		) {
			return count + 1;
		}
		return count;
	}, 0);

	const { amountFrom, amountTo } = selector;
	if (amountFrom || amountTo) {
		count += 1;
	}
	return count;
}