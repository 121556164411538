import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../store';
import { logout, setLoginErrorMessage, showErrorToast, updateUserRefreshToken, updateUserToken } from '../actions';
import { getOAuthTokensWithRefreshToken } from '../services/BackendService';
import _t from 'counterpart';
import { oAuthClientId, oAuthClientSecret } from './oAuthLogin';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshAuthLogic = async (failedRequest: any) => {
  const {
    refreshToken,
    impersonation
  } = store.getState().user;
  if (impersonation || !refreshToken) {
    store.dispatch(logout());
    store.dispatch(setLoginErrorMessage(_t('global.session-expired')));
    return Promise.reject(failedRequest);
  }
  if (refreshToken) {
    try {
      const {
        access_token: token,
        refresh_token: newRefreshToken
      } = await getOAuthTokensWithRefreshToken(refreshToken, oAuthClientId as string, oAuthClientSecret as string, `${window.location.origin}/login`);
      store.dispatch(updateUserToken(token));
      store.dispatch(updateUserRefreshToken(newRefreshToken));
      failedRequest.response.config.headers.authorization = `Bearer ${token}`;
      return await Promise.resolve();
    } catch (e) {
      store.dispatch(logout());
      return Promise.reject(failedRequest);
    }
  }

  return Promise.reject(failedRequest);
};

export const createTokenRefreshInterceptor = (instance: AxiosInstance) => {
  return createAuthRefreshInterceptor(
    instance,
    refreshAuthLogic,
  );
};

export const handleRequest = (config: AxiosRequestConfig) => {
  if (config.url?.endsWith('oauth/token') || (config.url?.endsWith('users/self') && config.headers.authorization)) {
    return config;
  }
  const { token } = store.getState().user;
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
};

export const handleSuccess = (response: AxiosResponse) => response;

export const handleReject = async (error: AxiosError) => {
  const responseStatusCode = error.response?.status;
  if (responseStatusCode) {
    if (responseStatusCode === 400 && error.config.url?.endsWith('oauth/token')) {
      store.dispatch(logout());
    } else if (responseStatusCode === 503 || responseStatusCode === 504) {
      store.dispatch(logout());
      store.dispatch(showErrorToast(_t('global.maintenance-mode')));
    }
  }
  return Promise.reject(error);
};
