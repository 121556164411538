import React from 'react';
import { CCard, CRow } from '@coreui/react';

interface IDoubleStatProps {
	title: string;
	titleSuffix?: React.ReactNode;
	color: string;
	statOneTitle: string;
	statOneValue: string | number;
	statTwoTitle: string;
	statTwoValue: string | number;
}

const DoubleStat = (props: IDoubleStatProps) => {
	const { title, titleSuffix, color, statOneTitle, statOneValue, statTwoTitle, statTwoValue } = props;

	return (
		<CCard className={`card--stat card--${color} mb-0 align-self-stretch d-flex flex-column justify-content-between`}>
			<CRow className="d-flex justify-content-between">
				<span className="text-white h4">{title}</span>
				{titleSuffix && <div>{titleSuffix}</div>}
			</CRow>
			<CRow className="d-flex" style={{ gap: '15px' }}>
				<div className="d-flex flex-column">
					<span className="text-white">{statOneTitle}</span>
					<span className="text-white h4">
						<b>{statOneValue}</b>
					</span>
				</div>
				<div className="d-flex flex-column">
					<span className="text-white">{statTwoTitle}</span>
					<span className="text-white h4">
						<b>{statTwoValue}</b>
					</span>
				</div>
			</CRow>
		</CCard>
	);
};

export default DoubleStat;
