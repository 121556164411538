import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react';
import _t from 'counterpart';
import { MainTitle } from '../../components/MainTitle/MainTitle';
import { Callout } from '../../components/Callout';
import LogoUploader from '../../components/LogoUpload/LogoUploader';
import FlexChildEqualWidth from '../../components/FlexChildEqualWidth';
import { useMutation, useQuery } from 'react-query';
import {
  deleteLogos,
  getLogos,
  uploadLogos,
} from '../../services/BackendService';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast, showSuccessToast } from '../../actions';
import { extractErrorMessage } from '../../helpers';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { IGreyLabelLogo } from './types';
import { blobUrlToDataArray } from '../../helpers/documentHelpers';

interface IUploadParams {
  squareUrl: string;
  rectangularUrl: string;
}

const MyBrandPage = () => {
  const [squareImage, setSquareImage] = useState<string | null>(null);
  const [rectangularImage, setRectangularImage] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const logosQuery = useQuery(['grey-label-logos'], () => getLogos(), {
    onError: (e) => {
      dispatch(showErrorToast(extractErrorMessage(e)));
    },
    onSuccess: (data: IGreyLabelLogo | null) => {
      if (data) {
        const { squareLogo, rectangularLogo } = data;
        setSquareImage(squareLogo);
        setRectangularImage(rectangularLogo);
      }
    },
    refetchOnWindowFocus: false,
  });

  const logoUploadMutation = useMutation(
    async ({ squareUrl, rectangularUrl }: IUploadParams) => {
      const bytesSquare = await blobUrlToDataArray(squareUrl);
      const bytesRectangular = await blobUrlToDataArray(rectangularUrl);
      await uploadLogos(bytesSquare, bytesRectangular);
    },
    {
      onSuccess: () => {
        dispatch(showSuccessToast(_t('my-brand.logos-uploaded-successfully')));
        logosQuery.refetch();
      },
      onError: (e) => {
        dispatch(showErrorToast(extractErrorMessage(e)));
      },
    }
  );

  const logoDeleteMutation = useMutation(() => deleteLogos(), {
    onSuccess: () => {
      dispatch(showSuccessToast(_t('my-brand.logos-deleted-successfully')));
      logosQuery.refetch();
      setSquareImage(null);
      setRectangularImage(null);
    },
    onError: (e) => {
      dispatch(showErrorToast(extractErrorMessage(e)));
    },
  });

  const invokeLogoUploadMutation = () => {
    if (squareImage && rectangularImage) {
      logoUploadMutation.mutate({
        squareUrl: squareImage,
        rectangularUrl: rectangularImage,
      });
    }
  };

  const invokeLogoDeleteMutation = () => {
    logoDeleteMutation.mutate();
  };

  const isUploadDisabled = squareImage === null || rectangularImage === null;

  return (
    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>{_t('sidebar.my-brand')}</MainTitle>
          <Callout
            color="primary"
            label={_t('my-brand.callout-title')}
            value={<p>{_t('my-brand.callout-paragraph')}</p>}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol md={6}>
          <CCard>
            <CCardHeader className="pb-0 mb-0">
              <CCardTitle>{_t('my-brand.square-logo')}</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex justify-content-between">
                <FlexChildEqualWidth>
                  <LogoUploader
                    savedFile={squareImage}
                    setFile={setSquareImage}
                    minHeight={128}
                    minWidth={128}
                  />
                </FlexChildEqualWidth>
                <FlexChildEqualWidth>
                  <div className="ml-4">
                    <ul className="pl-2 ml-2">
                      <li>{_t('my-brand.png-format')}</li>
                      <li>{_t('my-brand.transparent-background')}d</li>
                      <li>{_t('my-brand.size-min-square')}</li>
                    </ul>
                    <p>{_t('my-brand.logo-paragraph')}</p>
                  </div>
                </FlexChildEqualWidth>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md={6}>
          <CCard>
            <CCardHeader className="pb-0 mb-0">
              <CCardTitle>{_t('my-brand.rectangular-logo')}</CCardTitle>
            </CCardHeader>
            <CCardBody>
              <div className="d-flex justify-content-between">
                <FlexChildEqualWidth>
                  <LogoUploader
                    savedFile={rectangularImage}
                    setFile={setRectangularImage}
                    minHeight={50}
                    minWidth={128}
                  />
                </FlexChildEqualWidth>
                <FlexChildEqualWidth>
                  <div className="ml-4">
                    <ul className="pl-2 ml-2">
                      <li>{_t('my-brand.png-format')}</li>
                      <li>{_t('my-brand.transparent-background')}d</li>
                      <li>{_t('my-brand.size-min-rectangular')}</li>
                    </ul>
                    <p>{_t('my-brand.logo-paragraph')}</p>
                  </div>
                </FlexChildEqualWidth>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ButtonWithLoader
        title={_t('my-brand.upload-logos')}
        buttonColor="primary"
        onClick={invokeLogoUploadMutation}
        disabled={isUploadDisabled}
        isLoading={logoUploadMutation.isLoading}
      />
      <ButtonWithLoader
        title={_t('my-brand.delete-logos')}
        className="ml-2"
        buttonColor="danger"
        onClick={invokeLogoDeleteMutation}
        disabled={logosQuery.data === null}
        isLoading={logoDeleteMutation.isLoading}
      />
    </CContainer>
  );
};

export default MyBrandPage;
