import React, { useMemo } from 'react';
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CContainer,
	CDataTable,
	CLink,
	CRow,
} from '@coreui/react';
import _t from 'counterpart';
import Loading from '../components/Loading';
import { loadDashboardStats } from '../services/BackendService';
import { useQuery } from 'react-query';
import { NetworkError } from '../components/NetworkError/NetworkError';
import { formatCurrency, formatDateTime, getIconNameForCountry } from '../helpers';
import DoubleStat from '../components/DoubleStat';
import CIcon from '@coreui/icons-react';
import { IDashboardReferral } from 'src/types';

const DashboardPage = () => {
	const slots = useMemo(
		() => ({
			country: (user: any) => (
				<td className="align-middle">
					{getIconNameForCountry(user.country.toLowerCase()) ? (
						<CIcon name={getIconNameForCountry(user.country.toLowerCase())!} size="xl" title={user.countryName} />
					) : (
						user.country
					)}
				</td>
			),
			lastSignInDate: ({ lastSignInDate }: IDashboardReferral) => <td>{formatDateTime(lastSignInDate)}</td>,
		}),
		[]
	);

	const fields = useMemo(
		() => [
			{ key: 'name', label: _t('global.name') },
			{ key: 'country', label: _t('global.country') },
			{ key: 'lastSignInDate', label: _t('dashboard.column.signed-in') },
		],
		[]
	);

	const { data, isError, isLoading, refetch } = useQuery(['dashboard-stats'], loadDashboardStats);

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		return <NetworkError handleRetry={refetch} />;
	}

	return (
		<CContainer fluid className="main-holder main-holder--stats">
			<CRow className="mb-2">
				<CCol className="dashboard-grid">
					<DoubleStat
						title={_t('dashboard.referral-earnings')}
						color="blue"
						titleSuffix={
							<CLink href="/commissions">
								<CButton className="light-white-bg text-black">{_t('dashboard.commissions')}</CButton>
							</CLink>
						}
						statOneTitle={_t('dashboard.total')}
						statOneValue={formatCurrency(data?.totalBalance ?? 0)}
						statTwoTitle={_t('dashboard.unpaid')}
						statTwoValue={formatCurrency(data?.unpaidBalance ?? 0)}
					/>
					<DoubleStat
						title={_t('dashboard.new-sign-ups')}
						color="green"
						statOneTitle={_t('dashboard.this-month')}
						statOneValue={data?.signUpsThisMonth ?? 0}
						statTwoTitle={_t('dashboard.last-month')}
						statTwoValue={data?.signUpsLastMonth ?? 0}
					/>
					<DoubleStat
						title="CPAs"
						color="purple"
						statOneTitle={_t('dashboard.this-month')}
						statOneValue={formatCurrency(data?.cpasThisMonth ?? 0)}
						statTwoTitle={_t('dashboard.last-month')}
						statTwoValue={formatCurrency(data?.cpasLastMonth ?? 0)}
					/>
				</CCol>
			</CRow>

			<CRow className="mb-2">
				<CCol className="dashboard-grid">
					<DoubleStat
						title={_t('dashboard.monthly-earnings')}
						color="red"
						statOneTitle={_t('dashboard.this-month')}
						statOneValue={formatCurrency(data?.earningsThisMonth ?? 0)}
						statTwoTitle={_t('dashboard.last-month')}
						statTwoValue={formatCurrency(data?.earningsLastMonth ?? 0)}
					/>

					<DoubleStat
						title={_t('dashboard.trading-volume')}
						color="orange"
						statOneTitle={_t('dashboard.this-month')}
						statOneValue={data?.volumesThisMonth ?? 0}
						statTwoTitle={_t('dashboard.last-month')}
						statTwoValue={data?.volumesLastMonth ?? 0}
					/>

					<DoubleStat
						title={_t('dashboard.active-clients')}
						color="blue"
						statOneTitle={_t('dashboard.this-month')}
						statOneValue={data?.totalLoginsThisMonth ?? 0}
						statTwoTitle={_t('dashboard.last-month')}
						statTwoValue={data?.totalLoginsLastMonth ?? 0}
					/>
				</CCol>
			</CRow>

			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pt-3 mt-0 mb-0 pb-0">
							<CCardTitle>{_t('dashboard.last-5-signed-in-referrals')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CDataTable striped fields={fields} scopedSlots={slots} items={data?.last5SignIns} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</CContainer>
	);
};

export default DashboardPage;
