import React from 'react';
import { CCallout } from '@coreui/react';

interface IProps {
  value: string | React.ReactNode;
  label: string;
  color: string;
}

interface IState {

}

export class Callout extends React.PureComponent<IProps, IState> {
  render() {
    const { value, label, color } = this.props;

    return (
      <CCallout
        color={color}
        className="bg-white"
      >
        <small className="text-value-lg">
          {label}
        </small>
        <div className="text-muted">
          {value}
        </div>
      </CCallout>
    );
  }
}
