import React from 'react';
import { RouteChildrenProps, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { CFade } from '@coreui/react';

interface IFadeInRoute {
  children: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode,
  enabled?: boolean,
}

export const FadeInRoute = ({ children, enabled = true, ...routeProps }: IFadeInRoute & RouteProps) => (
  enabled ? (
    <Route {...routeProps}>
      <CFade className="d-flex flex-grow-1 flex-column">
        {children}
      </CFade>
    </Route>
  ) : null
);

FadeInRoute.defaultProps = {
  enabled: true,
};
