import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CRow, } from '@coreui/react';
import _t from 'counterpart';
import { getOAuthState, requestOAuthLogin } from '../helpers/oAuthLogin';
import { useAppDispatch, useAppSelector, useQueryParams } from '../helpers/customHooks';
import { requestOAuthTokens } from '../actions';
import CenteredSpinner from '../components/CenteredSpinner';

const crmURL = process.env.REACT_APP_CRM_ROOT;
const registrationLink = `${crmURL}`;

interface LocationState {
  referrer?: string;
}

const LoginPage = () => {
  const { token, communicationLanguage } = useAppSelector((state) => state.user);
  const loading = useAppSelector((state) => state.login.loading);
  const location = useLocation<LocationState>();
  const history = useHistory();

  const query = useQueryParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const code = query.get('code');
    const state = query.get('state');
    const requestLogin = query.get('request_login');
    if (code && state) {
      if (state === getOAuthState()) {
        dispatch(requestOAuthTokens(code));
      }
    } else if (requestLogin) {
      requestOAuthLogin(communicationLanguage);
    }
  }, []);

  const getAndClearReferrer = (): string => {
    const referrer = sessionStorage.getItem('referrer');
    if (referrer) {
      sessionStorage.removeItem('referrer');
      return referrer;
    }
    return '/';
  };

  useEffect(() => {
    if (token) {
      history.push(getAndClearReferrer());
    }
  }, [token]);

  const saveReferrer = (url: string) => {
    sessionStorage.setItem('referrer', url);
  };

  const onLogin = (event: any) => {
    event.preventDefault();
    if (location.state?.referrer) {
      saveReferrer(location.state?.referrer);
    }
    requestOAuthLogin(communicationLanguage);
  };

  if (loading) {
    return (
      <div className="vh-100 d-flex justify-content-center">
        <CenteredSpinner />
      </div>
    );
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="10" lg="8" xl="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <h1 className="mb-5">{_t('login.title')}</h1>
                  <CCol className="d-flex flex-column align-items-center">
                    <CRow>
                      <CButton
                        onClick={onLogin}
                        color="primary"
                        className="px-4"
                        style={{
                          width: 150
                        }}
                      >
                        {_t('action.login')}
                      </CButton>
                    </CRow>
                    <CRow>
                      <p className="text-muted my-2">{_t('global.or')}</p>
                    </CRow>
                    <CRow>
                      <CButton
                        href={registrationLink}
                        color="secondary"
                        className="px-4"
                        style={{
                          width: 150
                        }}
                      >
                        {_t('action.sign-up')}
                      </CButton>
                    </CRow>
                  </CCol>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginPage;
