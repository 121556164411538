import React from 'react';
import _t from 'counterpart';

const HowItWorksPage = () => {
  if (['es', 'ur', 'ru', 'de', 'tr', 'ptbr'].includes(_t.getLocale())) {
    return (
      <iframe src={`https://24domino.com/${_t.getLocale()}/marketing-commissions`} title="Affiliate Commission Model" style={{ border: 'none', flex: 1 }} />
    );
  }
  return <iframe src="https://24domino.com/marketing-commissions" title="Affiliate Commission Model" style={{ border: 'none', flex: 1 }} />;
};

export default HowItWorksPage;
