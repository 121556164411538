export const blobUrlToDataArray = async (url: string): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    try {
      let blob;
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.onload = () => {
        const recoveredBlob = xhr.response;
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          // @ts-ignore
          blob = new Uint8Array(e.target.result);
          resolve(blob);
        });
        reader.readAsArrayBuffer(recoveredBlob);
      };

      xhr.open('GET', url);
      xhr.send();
    } catch (e) {
      reject(e);
    }
  });
};
