import { MessageType } from './types';
import _t from 'counterpart';

export const CONTACT_METHODS = [
	{
		id: MessageType.Push,
		name: _t(`messages.PUSH`),
	},
	{
		id: MessageType.Message,
		name: _t(`messages.MESSAGE`),
	},
];
