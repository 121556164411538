import wintrado from './brands/wintrado/logo.svg';
import gfmarkets from './brands/gfmarkets/logo.svg';
import xbloomcapital from './brands/xbloomcapital/logo.png';
import empirexinvest from './brands/empirexinvest/logo.png';
import utocapital from './brands/utocapital/logo.png';
import axesecurities from './brands/axesecurities/logo.png';

const logos = {
  wintrado,
  gfmarkets,
  xbloomcapital,
  empirexinvest,
  utocapital,
  axesecurities
} as { [brand: string]: string };

export default logos[process.env.REACT_APP_BRAND!];
