export interface IDownlineStats {
  level: number;
  registrations: number;
  pushNotifications: number;
  downloads: number;
  totalDeposits: number;
}

export interface IWallet {
  id: string;
  username: string;
  customerId: string;
  currency: string;
  groupId: number;
  platform: string;
  status: string;
  leverage: number;
  type: string;
  isDemo: boolean;
}

export interface IPayoutRequest {
  id: number;
  payoutWallet: { id: string; username: string };
  requestedAt: Date;
  approvedAt: Date | null;
  rejectedAt: Date | null;
  rejectReason: string | null;
  updatedAt: Date;
  createdAt: Date;
}

export interface IMessage {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  noRecipients: number;
  text: string;
  subject: string | null;
}

export interface ITargetGroup {
  id: string;
  name: string;
  noRecipients: { [method: string]: number };
}

export interface ILandingPage {
  preview: string;
  name: string;
  language: string | null;
  url: string;
}

export interface IDashboardChartDatum {
  date: Date;
  value: number;
}

export interface IDashboardReferral {
  name: string;
  id: string;
  country: string;
  lastSignInDate: string;
}

export interface IDashboardStats {
  signUpsLastMonth: number;
  signUpsThisMonth: number;
  earningsThisMonth: number;
  earningsLastMonth: number;
  cpasLastMonth: number;
  cpasThisMonth: number;
  volumesLastMonth: number;
  volumesThisMonth: number;
  totalBalance: number;
  unpaidBalance: number;
  totalLoginsLastMonth: number;
  totalLoginsThisMonth: number;
  last5SignIns: Array<IDashboardReferral>;
}

export enum UserRole {
  Admin = 'ADMIN',
  Trader = 'TRADER',
  Affiliate = 'AFFILIATE',
  Leader = 'LEADER',
}

export enum MessageType {
  Push = 'PUSH',
  Message = 'MESSAGE',
}

export enum MessageStatus {
  Sent = 'SENT',
  Created = 'CREATED',
}

export interface ICustomerMessage {
  id: string;
  createdAt: Date;
  text: string;
  type: MessageType;
  status: MessageStatus;
}

export interface ICustomer {
  id: string | null;
  accountId: number | null;
  name: string | null;
  email: string | null;
  mobilenumber: string | null;
  depositTotal: number | null;
  percent: number | null;
  totalCoinsEarned: number | null;
  danglingCoins: number | null;
  country: string | null;
  countryName: string | null;
  status: 'active' | 'deactivated';
  availableContactMethods: Array<MessageType>;
  messages: Array<ICustomerMessage>;
  roles: Array<UserRole>;
  appInstalledAt: Date | null;
  totalCommission: number | null;
  tradingBalance: {
    balance: number | null;
    currency: string | null;
  };
  totalLogins: number | null;
  lastLoginDate: Date | null;
  notes: string;
  sendMessageErrors: Array<any> | null;
  isBookmarked?: boolean;
  hasFTD: boolean;
  source: string | null;
}

export interface ITransaction {
  id: string;
  createdAt: Date;
  amount: number;
  reason: string;
  sourceCustomer?: {
    id: string;
    name: string;
  };
}

export interface IBalanceWithCurrency {
  balance: number;
  currency: string;
}

export enum CommissionType {
  CPA = 'CPA',
  VOLUME = 'VOLUME',
  PROFIT_SHARE = 'PROFIT_SHARE',
}

export enum ActionType {
  FTD = 'FTD',
  Trade = 'Trade',
}

export interface IAffiliateCommissionLedger {
  id: number;
  introducer: { name: string; id: string };
  referral: { name: string; id: string };
  commissionType: CommissionType;
  actionType: ActionType;
  actionDetails: string;
  commissionAmount: number;
  correctedCommissionAmount: number | null;
  currency: string;
  actionTimestamp: string;
  payoutAt: string | null;
  updatedAt: string;
  adminComments: string;
  createdAt: string;
}

export interface ICommissionFiltersDTO {
  statuses: Array<string>;
}
