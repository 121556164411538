import React, { useState, useRef } from 'react';
import {
  CButton,
  CLabel,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CAlert
} from '@coreui/react';
import DateRangePicker, { DateRange } from './DateRangePicker';
import _t from 'counterpart';
import { countExports } from '../services/BackendService';
import { useQuery } from 'react-query';
import queryString from 'query-string';

interface IProps {
  xlsxLinkBase: string;
  xlsxCountBase: string;
  showExportPopup: boolean;
  onClose: Function;
  token: any;
}

export const ExportDocument = ({ xlsxLinkBase, xlsxCountBase, showExportPopup, onClose, token }: IProps) => {
  const getStartDate = () => {
    const initialFromDate = new Date();
    initialFromDate.setMonth(new Date().getMonth() - 1);
    initialFromDate.setHours(0, 0, 0, 0);
    return initialFromDate;
  };
  const getEndDate = (): Date => {
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  };

  const [range, setRange] = useState<DateRange>({
    startDate: getStartDate(),
    endDate: getEndDate(),
  });
  const [error, setError] = useState<string>('');
  const linkRef = useRef(null);

  const dateRangeChanged = (dateRange: DateRange) => {
    setRange(dateRange);
  };

  const params : any = { token };
  const { startDate, endDate } = range;

  if (range) {
    if (startDate && endDate) {
      params.from = startDate;
      params.to = endDate;
    }
  }

  const onCloseWrapper = () => {
    setError('');
    onClose(false);
  };

  const xlsxLink = `${xlsxLinkBase}?${queryString.stringify(params)}`;

  const { data, error: countError, isLoading } = useQuery<any>(['admin-xlsx-count', range], () => countExports(xlsxCountBase, startDate, endDate));
  const disabled = !countError && !isLoading && data?.count && data.count !== 0;

  const tryExport = () => {
    if (range) {
      const { startDate, endDate } = range;
      if (!startDate || !endDate) {
        setError(_t('global.invalid-date-range'));
      } else {
        const a = linkRef.current as unknown as HTMLAnchorElement;
        a.click();
        onCloseWrapper();
      }
    }
  };

  return (
    <CModal show={showExportPopup} onClose={onCloseWrapper}>
      <CModalHeader className="d-flex flex-column">
        <CModalTitle>{_t('global.export')}</CModalTitle>
        {(data?.count !== undefined && data.count === 0) && <CAlert color="danger">{_t('errors.row-count-zero')}</CAlert>}
      </CModalHeader>
      <CModalBody>
        <CLabel>
          {_t('global.select-date')}
        </CLabel>
        <DateRangePicker
          onRangeChanged={dateRangeChanged}
          initialStartDate={getStartDate()}
          initialEndDate={new Date()}
        />
        {error && <CLabel className="text-danger">{error}</CLabel>}
      </CModalBody>
      <CModalFooter className="d-flex flex-column">
        <a href={xlsxLink} ref={linkRef} className="d-none">a</a>
        <CButton
          color="primary"
          onClick={tryExport}
          disabled={!disabled}
        >
          {`${_t('global.export')} XLSX`}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
