import React, { useState } from 'react';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CSpinner,
} from '@coreui/react';
import _t from 'counterpart';
import EditSourceModal from './EditSourceModal';
import { ICustomer } from '../../types';
import { useMutation } from 'react-query';
import {
  bookmarkCustomer,
  removeBookmark,
} from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import { showErrorToast } from '../../actions';
import { useAppDispatch } from '../../helpers/customHooks';

interface IProps {
  customer: ICustomer;
  refetch: () => void;
}

const CustomerPageHeader = ({ customer, refetch }: IProps) => {
  const [setSourceShow, setSetSourceShow] = useState<boolean>(false);

  const showSetSourceModal = () => {
    setSetSourceShow(true);
  };

  const hideSourceModal = (fetchAgain?: boolean) => {
    if (fetchAgain) {
      refetch();
    }
    setSetSourceShow(false);
  };

  const dispatch = useAppDispatch();

  const showError = (errorMessage: string) => {
    const error = extractErrorMessage(errorMessage);
    dispatch(showErrorToast(error));
  };

  const bookmarkCustomerMutation = useMutation(
    (id: string) => bookmarkCustomer(id),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (e: any) => {
        showError(e);
      },
    }
  );

  const removeBookmarkMutation = useMutation(
    (id: string) => removeBookmark(id),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (e: any) => {
        showError(e);
      },
    }
  );

  const bookmarkOperationLoading =
    bookmarkCustomerMutation.isLoading || removeBookmarkMutation.isLoading;

  const addOrRemoveBookmark = () => {
    if (customer.id) {
      if (customer.isBookmarked) {
        removeBookmarkMutation.mutate(customer.id);
      } else {
        bookmarkCustomerMutation.mutate(customer.id);
      }
      customer.isBookmarked = !customer.isBookmarked;
    }
  };

  return (
    <CRow>
      <CCol md={12}>
        <div className="float-left">
          <div className="d-flex align-items-center">
            <h2 className="p-0 m-0">{customer.name}</h2>
            {bookmarkOperationLoading && (
              <CSpinner color="primary" size="sm" className="ml-3" />
            )}
            {!bookmarkOperationLoading && (
              <CButton
                onClick={addOrRemoveBookmark}
                className="p-0 ml-2"
                title={
                  customer.isBookmarked
                    ? _t('customer.remove-bookmark')
                    : _t('customer.bookmark')
                }
              >
                <CIcon
                  name={customer.isBookmarked ? 'cis-bookmark' : 'cil-bookmark'}
                  size="xl"
                  className="bookmark"
                />
              </CButton>
            )}
          </div>
          <p>
            {_t('customer.title')}
            &nbsp;
            <i>{customer.name}</i>
          </p>
        </div>
        <div className="float-right">
          <CDropdown>
            <CDropdownToggle caret color="primary">
              {_t('customer.customer-options')}
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={showSetSourceModal}>
                {_t('customer.change-source')}
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </CCol>
      <EditSourceModal
        show={setSourceShow}
        onHide={hideSourceModal}
        customerId={customer.id ?? ''}
      />
    </CRow>
  );
};

export default CustomerPageHeader;
