import React from 'react';
import { CCol, CContainer, CRow } from '@coreui/react';
import _t from 'counterpart';
import Loading from '../components/Loading';
import { Callout } from '../components/Callout';
import { loadLandingPages } from '../services/BackendService';
import { useQuery } from 'react-query';
import { NetworkError } from '../components/NetworkError/NetworkError';
import LandingPageCard from '../components/LandingPageCard';
import { MainTitle } from '../components/MainTitle/MainTitle';

const MarketingPage = () => {
  const landingPagesQuery = useQuery('landing-pages', loadLandingPages);

  if (landingPagesQuery.isLoading) {
    return <Loading />;
  }

  if (landingPagesQuery.isError) {
    return <NetworkError handleRetry={landingPagesQuery.refetch} />;
  }

  return (
    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>
            {_t('landing-page.title')}
          </MainTitle>
          <Callout
            color="primary"
            label={_t('landing-page.title')}
            value={(
              <>
                {_t('landing-page.marketing-efforts')}
                <br />
                {_t('landing-page.using')}
              </>
              )}
          />
          <CRow>
            {landingPagesQuery.data!.map((landingPage) => (
              <LandingPageCard
                key={`lp-${landingPage.url}`}
                landingPage={landingPage}
              />
            ))}
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default MarketingPage;
