import _t from 'counterpart';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/ar';
import 'moment/locale/tr';
import 'moment/locale/ru';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/hi';
import 'moment/locale/ur';
import 'moment/locale/sw';
import 'moment/locale/ms';
import 'moment/locale/ja';
import 'moment/locale/vi';
import 'moment/locale/km';
import 'moment/locale/zh-cn';

export const correctedMomentLocales : any = {
  tz: 'sw',
  ke: 'sw',
  zh: 'zh-cn',
};

_t.registerTranslations('en', require('./en.json'));
_t.registerTranslations('es', require('./es.json'));
_t.registerTranslations('fr', require('./fr.json'));
_t.registerTranslations('pt', require('./ptbr.json'));
_t.registerTranslations('ar', require('./ar.json'));
_t.registerTranslations('tr', require('./tr.json'));
_t.registerTranslations('ru', require('./ru.json'));
_t.registerTranslations('it', require('./it.json'));
_t.registerTranslations('de', require('./de.json'));
_t.registerTranslations('hi', require('./hi.json'));
_t.registerTranslations('ur', require('./ur.json'));
_t.registerTranslations('tz', require('./sw.json'));
_t.registerTranslations('ke', require('./sw.json'));
_t.registerTranslations('ms', require('./ms.json'));
_t.registerTranslations('ja', require('./ja.json'));
_t.registerTranslations('vi', require('./vi.json'));
_t.registerTranslations('km', require('./km.json'));
_t.registerTranslations('zh', require('./zh.json'));

export const supportedLocales = ['en', 'es', 'fr', 'pt', 'ar', 'tr', 'ru', 'it', 'de', 'hi', 'ur', 'ke', 'ms', 'ja', 'vi', 'km', 'zh'];
