import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import _t from 'counterpart';
import { CButton, CCol, CForm, CFormGroup, CLabel, CRow } from '@coreui/react';
import { ISelectOption } from '../../components/SelectMultipleFilter';
import { shallowEqual } from 'react-redux';
import { setReferralFilters } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { getSources } from '../../services/BackendService';
import SelectMultipleFilter from '../../components/SelectMultipleFilter';

interface IProps {
  show: boolean;
  onFilterChanged: () => void;
}

const ReferralsFilters = ({ show, onFilterChanged }: IProps) => {
  const dispatch = useAppDispatch();
  const activeFilters = useAppSelector(
    (state) => state.filters.referralFilters,
    shallowEqual
  );
  const setFilters = useCallback(
    (filters: Partial<{ sources: Array<ISelectOption> }>) => {
      onFilterChanged();
      dispatch(setReferralFilters(filters));
    },
    [dispatch, onFilterChanged]
  );

  useQuery(['referral-sources'], getSources, {
    onSuccess: (data: Array<string>) => {
      setFilters({
        sources: data.map((source: string) => {
          const foundOption = activeFilters.sources.find(
            (option: ISelectOption) => option.value === source
          );
          return {
            value: source,
            label: source,
            enabled: foundOption ? foundOption.enabled : false,
          };
        }),
      });
    },
  });

  const onSourceOptionChanged = (toChange: ISelectOption) => {
    const filters = [...activeFilters.sources];
    const current = filters.find(
      (option: ISelectOption) => option.value === toChange.value
    );
    if (current) {
      current.enabled = !current.enabled;
    }

    setFilters({
      sources: filters,
    });
  };

  const onSourceReset = () => {
    onSourceAllChanged(false);
  };

  const onSourceAllChanged = (checked: boolean) => {
    const agents = [...activeFilters.sources];
    agents.forEach((option: ISelectOption) => (option.enabled = checked));
    setFilters({
      sources: agents,
    });
  };

  return (
    <CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
      <CRow>
        <CCol md={4}>
          <CFormGroup>
            <div className="d-flex align-items-center justify-content-between">
              <CLabel className="m-0" htmlFor="regdate">
                {_t('customer.source')}
              </CLabel>
              <CButton
                className="mb-0 p-0 text-danger reset-single"
                onClick={onSourceReset}
              >
                {_t('action.reset').toUpperCase()}
              </CButton>
            </div>
            <SelectMultipleFilter
              data={activeFilters.sources}
              onChange={onSourceOptionChanged}
              onAllChange={onSourceAllChanged}
            />
          </CFormGroup>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default ReferralsFilters;
