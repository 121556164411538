import React from 'react';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const FlexChildEqualWidth = ({ children, className }: IProps) => {
  return (
    <div className={className} style={{ flexBasis: '0', flexGrow: '1' }}>
      {children}
    </div>
  );
};

export default FlexChildEqualWidth;
