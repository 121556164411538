import React from 'react';
import _t from 'counterpart';

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column my-auto">
      <h2>{_t('global.not-found-title')}</h2>
      <p>{_t('global.contact')}</p>
    </div>
  );
};

export default NotFound;
