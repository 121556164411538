import React from 'react';
import styles from './MainTitle.module.scss';

interface IProps {
  children: React.ReactNode;
}

export const MainTitle = ({ children }: IProps) => {
  return (
    <h2 className={styles.mainTitle}>
      {children}
    </h2>
  );
};
