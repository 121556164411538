import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import React from 'react';
import styles from './NetworkError.module.scss';
import _t from 'counterpart';

interface Props {
  handleRetry: () => void;
}

export const NetworkError: React.FC<Props> = ({ handleRetry }) => {
  const onRetry = (e: Event) => {
    e.preventDefault();
    handleRetry();
  };

  return (
    <div className={styles.container}>
      <CIcon name="cilWarning" className={styles.icon} size="3xl" />
      <h2 className={styles.title}>
        {_t('network-error.title')}
      </h2>
      <p className={styles.description}>
        {_t('network-error.failed-to-reach-servers')}
        <br />
        {_t('network-error.check-connectivity-try-again')}
      </p>
      <CButton
        color="primary"
        onClick={onRetry}
      >
        {_t('action.retry')}
      </CButton>
    </div>
  );
};
