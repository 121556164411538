import React, { useState } from "react";
import Dropzone from 'react-dropzone';
import _t from 'counterpart';
import CIcon from '@coreui/icons-react';
import './logoUploader.scss';
import { useAppDispatch } from '../../helpers/customHooks';
import { showErrorToast } from '../../actions';

interface IProps {
  allowMultipleFiles: boolean;
  addLogo: (url: string) => void;
  removeLogo: () => void;
  file: string | null;
  darkMode: boolean;
  onImageLoaded?: (image: HTMLImageElement) => void;
}

const ImageUploader = ({
  addLogo,
  removeLogo,
  file,
  darkMode,
  onImageLoaded,
}: IProps) => {
  const dispatch = useAppDispatch();

  const addThumbnail = (url: string) => {
    addLogo(url);
  };

  const removeThumbnail = () => {
    removeLogo();
  };

  const storeFiles = async (files: Array<any>) => {
    const file = files[0];

    const { name } = file;
    const extension = name.substring(
      name.lastIndexOf('.') + 1,
      name.length
    ) as string;

    if (extension.toLowerCase() !== 'png') {
      dispatch(showErrorToast(_t('my-brand.wrong-file-type')));
      return;
    }

    const url = URL.createObjectURL(file);
    addThumbnail(url);
  };

  return (
    <Dropzone multiple={false} onDrop={storeFiles}>
      {({ getRootProps, getInputProps }) => (
        <section className="dropzone-section">
          <div
            className={`dropzone ${darkMode ? 'dropzone-dark' : ''}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="dropzone-inner">
              {!file && (
                <div className="dropzone-center">
                  <CIcon name="cil-cloud-upload" size="xl" />
                  <span>{_t('my-brand.logo-drag-drop')}</span>
                </div>
              )}
              {file && (
                <img
                  src={file}
                  alt="current-logo"
                  className="preview"
                  onLoad={(e) => {
                    const image = e.target as HTMLImageElement;
                    onImageLoaded && onImageLoaded(image);
                  }}
                />
              )}
            </div>
          </div>
          {file && (
            <button className="close-button" onClick={removeThumbnail}>
              <CIcon name="cil-x" />
            </button>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export default ImageUploader;
