import { AnyAction } from 'redux';
import { LOGIN_FAILED, LOGIN_SUCCEEDED, REQUEST_OAUTH_TOKENS, } from '../actions';

export interface LoginState {
  loading: boolean;
}

const initialState: LoginState = {
  loading: false,
};

function loginReducer(state: LoginState | undefined, action: AnyAction): LoginState {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
      };

    case REQUEST_OAUTH_TOKENS:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

export default loginReducer;
