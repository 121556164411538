import { useReducer } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { AppState } from '../reducers';
import { useLocation } from 'react-router-dom';

export const useLegacyState = <D>(initialState: D) => useReducer(
  (state: object, update: any) => ({ ...state, ...update }),
  initialState
);

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};
