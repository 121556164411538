import { CSpinner } from '@coreui/react';
import React from 'react';
import '../scss/components/centeredSpinner.scss';

export default function CenteredSpinner(props: CSpinner) {
  return (
    <div className="centered-spinner">
      <CSpinner
        {...props}
      />
    </div>
  );
}
