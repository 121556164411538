import React from 'react';
import { Callout } from '../components/Callout';
import {
  CContainer,
} from '@coreui/react';
import _t from 'counterpart';
import { MainTitle } from '../components/MainTitle/MainTitle';

const SupportPage = () => (
  <CContainer fluid className="c-main main-holder">
    <MainTitle>
      {_t('support.title')}
    </MainTitle>
    <Callout
      color="primary"
      label={_t('support.title')}
      value={(
        <>
          {_t('global.contact', { email: process.env.REACT_APP_SUPPORT_EMAIL })}
        </>
        )}
    />
  </CContainer>
);

export default SupportPage;
