import axios from 'axios';
import { createTokenRefreshInterceptor, handleReject, handleRequest, handleSuccess } from '../helpers/interceptors';
import queryString from 'query-string';
import {
	ICustomer,
	ICustomerMessage,
	IDashboardStats,
	IDownlineStats,
	ILandingPage,
	IMessage,
	ITargetGroup,
	MessageType,
	ITransaction,
	IBalanceWithCurrency,
	IAffiliateCommissionLedger,
	IWallet,
	IPayoutRequest,
} from '../types';
import { IGreyLabelLogo } from './ImportTypes';

interface ILogin {
	username?: string;
	password?: string;
	refreshToken?: string;
}

const baseURL = process.env.REACT_APP_API_ROOT;
const client = axios.create({ baseURL });

createTokenRefreshInterceptor(client);
client.interceptors.response.use(handleSuccess, (error) => handleReject(error));
client.interceptors.request.use(handleRequest);

export async function login({ username, password, refreshToken }: ILogin) {
	let dataToSend;
	if (refreshToken) {
		dataToSend = {
			refresh_token: refreshToken,
		};
	} else {
		dataToSend = {
			username,
			password,
		};
	}
	const response = await client.post('/api/sessions', dataToSend);
	return response.data;
}

export async function loadCustomer(id: string): Promise<ICustomer> {
	const response = await client.get(`/api/affiliates/customers/${id}`);
	return {
		...response.data.customer,
		appInstalledAt: response.data.customer.appInstalledAt ? new Date(response.data.customer.appInstalledAt) : null,
		lastLoginDate: response.data.customer.lastLoginDate ? new Date(response.data.customer.lastLoginDate) : null,
		confirmedAt: response.data.customer.confirmedAt ? new Date(response.data.customer.confirmedAt) : null,
	};
}

export async function loadLandingPages(): Promise<ILandingPage[]> {
	const response = await client.get('/api/affiliates/landing_pages');
	return response.data.landing_pages;
}

export async function loadCustomerMessages(customerId: string, limit: number, offset: number): Promise<{ messages: ICustomerMessage[], count: number }> {
	const response = await client.get(`/api/affiliates/customers/${customerId}/messages?limit=${limit}&offset=${offset}`);
	const messages = response.data.messages.map((m: any) => ({
		...m,
		createdAt: new Date(m.createdAt),
		updatedAt: new Date(m.updatedAt),
	}));

	return { messages, count: response.data.count };
}

export async function loadGroupMessages(limit: number, offset: number): Promise<{ messages: IMessage[], count: number }> {
	const response = await client.get(`/api/affiliates/group_messages?limit=${limit}&offset=${offset}`);
	const messages = response.data.messages.map((m: any) => ({
		...m,
		createdAt: new Date(m.createdAt),
		updatedAt: new Date(m.updatedAt),
	}));

	return { messages, count: response.data.count };
}

export async function sendGroupMessage(type: MessageType, targetGroupId: string, text: string, subject: string): Promise<IMessage> {
	const response = await client.post('/api/affiliates/group_messages', {
		type,
		targetGroupId,
		text,
		subject
	});

	return response.data.message;
}

export async function sendCustomerMessage(type: MessageType, customerId: string, text: string, subject: string) {
	const response = await client.post(`/api/affiliates/customers/${customerId}/messages`, {
		type,
		customerId,
		text,
		subject
	});

	return response.data.message;
}

export async function updateCustomer(customerId: string, attributes: any): Promise<ICustomer> {
	const response = await client.put(`/api/affiliates/customers/${customerId}`, {
		...attributes,
	});

	return response.data;
}

export async function loadTargetGroups(): Promise<ITargetGroup[]> {
	const response = await client.get('/api/affiliates/target_groups');
	return response.data.targetGroups;
}

export async function loadTransactions(): Promise<ITransaction[]> {
	const response = await client.get('/api/affiliates/transactions');
	return response.data.transactions.map((t: any) => ({
		...t,
		createdAt: new Date(t.createdAt),
	}));
}

export async function loadAffiliateBalance(): Promise<number> {
	const response = await client.get('/api/affiliates/transactions/balance');
	return response.data.balance;
}

export async function loadPrimaryWalletBalance(): Promise<IBalanceWithCurrency> {
	const response = await client.get('/api/users/self/wallets/primary/balance');
	return response.data;
}

export async function requestAffiliateToTradingTransaction(): Promise<ITransaction> {
	const response = await client.post('/api/affiliates/transactions/request', {
		source: 'IB_WALLET',
		target: 'TRADING_WALLET',
	});

	return response.data.transaction;
}

export async function getPayoutRequests(
	limit: number,
	offset: number,
	order: 'ASC' | 'DESC',
	statuses?: Array<string>
): Promise<{ payoutRequests: Array<IPayoutRequest>; count: number }> {
	const response = await client.get('/api/commissions/payouts', {
		params: {
			limit,
			offset,
			order,
			statuses,
		},
	});
	return response.data;
}

export async function hasPendingPayoutRequest(): Promise<boolean> {
	const response = await client.get(`api/commissions/payouts/has_pending_request`);

	return response.data.hasPendingPayoutRequest;
}

export async function getPayoutRequest(
	id: number,
	limit: number,
	offset: number
): Promise<{
	payoutRequest: IPayoutRequest;
	commissions: Array<IAffiliateCommissionLedger>;
	commissionsCount: number;
	totalPayoutAmount: number;
}> {
	const response = await client.get(`/api/commissions/payouts/${id}`, {
		params: {
			limit,
			offset,
		},
	});

	return response.data.payoutRequest;
}

export async function createPayoutRequest(payoutWalletId: string) {
	await client.post('/api/commissions', {
		payoutWalletId,
	});
}

export async function requestCommissionPayout(payoutWalletId: string): Promise<void> {
	await client.post('/api/commissions', {
		payoutWalletId,
	});
}

export async function getCommissionLedgerRecords(
	limit: number,
	offset: number,
	order: 'ASC' | 'DESC',
	statuses: Array<string>
): Promise<{
	commissions: Array<IAffiliateCommissionLedger>;
	count: number;
	totalPayoutAmount: number;
}> {
	const response = await client.get('/api/commissions/ledger', {
		params: {
			limit,
			offset,
			order,
			statuses,
		},
	});

	return response.data.commissionLedger;
}

export async function loadCustomerWallets(): Promise<Array<IWallet>> {
	const response = await client.get('/api/users/self/wallets');

	return response.data.wallets;
}

export async function updateRefkey(refKey: string) {
	const response = await client.post('/api/affiliates/update_refkey', {
		refKey,
	});

	return response.data;
}

export async function loadDashboardStats(): Promise<IDashboardStats> {
	const response = await client.get('/api/affiliates/dashboard');

	return response.data;
}

export async function loadDownlineStats(): Promise<IDownlineStats[]> {
	const response = await client.get('/api/affiliates/analytics/downline', {});

	return response.data.stats;
}

export async function loadUserSelf(token?: string) {
	const headers: any = {};

	if (token) {
		headers.authorization = `Bearer ${token}`;
	}

	const response = await client.get('/api/users/self', { headers });
	return response.data;
}

export async function updateUserSelf(attributes: any) {
	const response = await client.put('/api/users/self', attributes);
	return response.data;
}

export async function getOAuthTokensWithCode(
	code: string,
	clientId: string,
	clientSecret: string,
	redirectUri: string,
	grantType: string
) {
	const response = await client.post(
		'/api/oauth/token',
		queryString.stringify({
			grant_type: grantType,
			code,
			client_id: clientId,
			client_secret: clientSecret,
			redirect_uri: redirectUri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);
	return response.data;
}

export async function oAuthLogout(refreshToken: string, redirectUri: string): Promise<string> {
	const response = await client.post('/api/oauth/logout', {
		refreshToken,
		redirectUri,
	});
	return response.data.redirectUri;
}

export async function getOAuthTokensWithRefreshToken(
	refreshToken: string,
	clientId: string,
	clientSecret: string,
	redirectUri: string
) {
	const response = await client.post(
		'/api/oauth/token',
		queryString.stringify({
			grant_type: 'refresh_token',
			client_id: clientId,
			client_secret: clientSecret,
			redirect_uri: redirectUri,
			refresh_token: refreshToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		}
	);
	return response.data;
}

export async function countExports(baseUrl: string, from: any, to: any) {
	const response = await client.get(baseUrl, {
		params: {
			from,
			to,
		},
	});
	return response.data;
}

export async function bookmarkCustomer(customerId: string) {
	const response = await client.post(`api/affiliates/bookmarks/${customerId}`);
	return response.data;
}

export async function removeBookmark(customerId: string) {
	const response = await client.delete(`api/affiliates/bookmarks/${customerId}`);
	return response.data;
}

export async function loadBookmarkedCustomers(search: string, limit: number, offset: number, sortBy: string) {
	const response = await client.get('/api/affiliates/bookmarks', {
		params: {
			limit,
			offset,
			search,
			sortBy,
		},
	});
	const { count, pages } = response.data;
	return {
		customers: response.data.customers.map((c: any) => ({
			...c,
			appInstalledAt: c.appInstalledAt ? new Date(c.appInstalledAt) : null,
		})),
		count,
		pages,
	};
}

export async function loadReferrals(
	search: string,
	limit: number,
	offset: number,
	orderBy: string,
	sources?: Array<string>
) {
	const response = await client.get('/api/affiliates/customers', {
		params: {
			limit,
			offset,
			search,
			orderBy,
			sources,
		},
	});

	return {
		customers: response.data.customers.map((c: any) => ({
			...c,
			appInstalledAt: c.appInstalledAt ? new Date(c.appInstalledAt) : null,
		})),
		pages: response.data.pages,
	};
}

export async function generateApiKey(): Promise<string> {
	const response = await client.post('api/affiliate/keys');
	return response.data.key;
}

export async function uploadLogos(squareLogo: Uint8Array, rectangularLogo: Uint8Array): Promise<IGreyLabelLogo> {
	const bodyFormData = new FormData();
	bodyFormData.append('squareLogo', new Blob([squareLogo]), 'squareLogo.png');
	bodyFormData.append('rectangularLogo', new Blob([rectangularLogo]), 'rectangularLogo.png');

	const response = await client.request({
		method: 'post',
		url: '/api/affiliates/grey_label/logos',
		data: bodyFormData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return response.data.logos;
}

export async function getLogos(): Promise<IGreyLabelLogo | null> {
	const response = await client.get('/api/affiliates/grey_label/logos');
	return response.data.logos;
}

export async function greyLabelingEnabled() {
	const response = await client.get(`api/affiliates/grey_label/enabled`);
	return response.data.enabled;
}

export async function deleteLogos(): Promise<{ success: boolean }> {
	const response = await client.delete('/api/affiliates/grey_label/logos');
	return response.data.logos;
}

export async function getSources(): Promise<Array<string>> {
	const response = await client.get('/api/affiliates/sources');
	return response.data;
}
