import React from 'react';
import { Switch } from 'react-router-dom';
import { FadeInRoute } from './FadeInRoute';
import DashboardPage from '../pages/DashboardPage';
import ReferralsPage from '../pages/ReferralsPage/ReferralsPage';
import CustomerPage from '../pages/CustomerPage/CustomerPage';
import MarketingPage from '../pages/MarketingPage';
import AnalyticsPage from '../pages/AnalyticsPage';
import MessagesPage from '../pages/MessagesPage';
import SupportPage from '../pages/SupportPage';
import HowItWorksPage from '../pages/HowItWorksPage';
import ProfilePage from '../pages/ProfilePage';
import BookmarksPage from '../pages/BookmarksPage';
import NotFound from './NotFound';
import ApiKeysPage from '../pages/ApiKeysPage';
import MyBrandPage from '../pages/BrandsPage/MyBrandPage';
import { useQueryClient } from 'react-query';
import CommissionsPage from '../pages/CommissionsPage';
import PayoutRequestsPage from '../pages/PayoutRequestsPage';
import PayoutRequestPage from '../pages/PayoutRequestPage';

export const AffiliateContent = () => {
	const queryClient = useQueryClient();
	const greyLabelingEnabled: boolean = queryClient.getQueryData(['greyLabelingEnabled']) || false;

	return (
		<Switch>
			<FadeInRoute exact path="/">
				<DashboardPage />
			</FadeInRoute>
			<FadeInRoute exact path="/referrals">
				<ReferralsPage />
			</FadeInRoute>
			<FadeInRoute exact path="/referrals/:customerId">
				<CustomerPage />
			</FadeInRoute>
			<FadeInRoute exact path="/marketing">
				<MarketingPage />
			</FadeInRoute>
			<FadeInRoute exact path="/analytics">
				<AnalyticsPage />
			</FadeInRoute>
			<FadeInRoute exact path="/messages">
				<MessagesPage />
			</FadeInRoute>
			<FadeInRoute exact path="/howitworks">
				<HowItWorksPage />
			</FadeInRoute>
			<FadeInRoute exact path="/support">
				<SupportPage />
			</FadeInRoute>
			<FadeInRoute exact path="/profile">
				<ProfilePage />
			</FadeInRoute>
			<FadeInRoute exact path="/bookmarks">
				<BookmarksPage />
			</FadeInRoute>
			<FadeInRoute exact path="/api_keys">
				<ApiKeysPage />
			</FadeInRoute>
			<FadeInRoute exact path="/commissions">
				<CommissionsPage />
			</FadeInRoute>
			<FadeInRoute exact path="/payout-requests">
				<PayoutRequestsPage />
			</FadeInRoute>
			<FadeInRoute exact path="/payout-requests/:id">
				<PayoutRequestPage />
			</FadeInRoute>
			<FadeInRoute enabled={greyLabelingEnabled} exact path="/my_brand">
				<MyBrandPage />
			</FadeInRoute>
			<FadeInRoute>
				<NotFound />
			</FadeInRoute>
		</Switch>
	);
};
