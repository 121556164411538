import { AnyAction } from 'redux';
import {
  LOAD_USER_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  LOGOUT,
  REQUEST_OAUTH_TOKENS,
  SET_LOGIN_ERROR_MESSAGE,
  UPDATE_USER_REFRESH_TOKEN,
  UPDATE_USER_TOKEN,
} from '../actions';

export enum UserRole {
  Admin = 'ADMIN',
  Trader = 'TRADER',
  Affiliate = 'AFFILIATE',
  Leader = 'LEADER'
}

export interface UserState {
  loggingIn: boolean;
  accountId: string | null;
  token: string | null;
  refreshToken: string | null;
  id: number | null;
  name: string | null;
  email: string | null;
  mobilenumber: string | null;
  refKey: string | null;
  avatarUrl: string | null;
  loginError: string | null;
  roles: UserRole[];
  communicationLanguage: string;
  impersonation?: {
    adminToken: string;
  };
}

const initialState: UserState = {
  loggingIn: false,
  accountId: null,
  token: null,
  refreshToken: null,
  id: null,
  name: null,
  email: null,
  mobilenumber: null,
  refKey: null,
  avatarUrl: null,
  loginError: null,
  roles: [],
  communicationLanguage: 'en',
};

function userReducer(state: UserState | undefined, action: AnyAction): UserState {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        token: null,
        refreshToken: null,
        name: null,
        avatarUrl: null,
        loginError: null,
        impersonation: undefined,
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loggingIn: false,
        accountId: action.user.accountId,
        token: action.token,
        refreshToken: action.refreshToken,
        name: action.user.name,
        email: action.user.email,
        mobilenumber: action.user.mobilenumber,
        refKey: action.user.refKey,
        avatarUrl: action.user.avatarUrl,
        roles: action.user.roles,
        loginError: null,
        impersonation: undefined,
        communicationLanguage: action.user.communicationLanguage,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loggingIn: false,
        token: null,
        name: null,
        email: null,
        mobilenumber: null,
        refKey: null,
        avatarUrl: null,
        roles: [],
        loginError: action.message,
        impersonation: undefined,
      };

    case SET_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        loginError: action.message,
      };

    case UPDATE_USER_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case UPDATE_USER_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.refreshToken,
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        accountId: action.user.accountId,
        name: action.user.name,
        email: action.user.email,
        mobilenumber: action.user.mobilenumber,
        refKey: action.user.refKey,
        avatarUrl: action.user.avatarUrl,
        roles: action.user.roles,
        communicationLanguage: action.user.communicationLanguage,
      };

    case REQUEST_OAUTH_TOKENS:
      return {
        ...state,
        loggingIn: true,
      };

    default:
      return state;
  }
}

export default userReducer;
