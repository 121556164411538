import posthog, { PostHogConfig } from 'posthog-js';

class Analytics {
  private initialized = false;

  public init(key: string, config: Partial<PostHogConfig>) {
    posthog.init(key, config);
    this.initialized = true;
  }

  public identifyUser(userId: string, email?: string) {
    if (this.initialized) {
      posthog.identify(userId);
      if (email) {
        posthog.people.set({ email });
      }
    }
  }

  public resetUserData() {
    if (this.initialized) {
      posthog.reset();
    }
  }

  public captureEvent(eventName: string, additionalData?: Record<string, any>) {
    if (this.initialized) {
      posthog.capture(eventName, additionalData);
    }
  }
}

const analytics = new Analytics();
export default analytics;
