import React, { useState } from 'react';
import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CButton,
  CCardHeader,
} from '@coreui/react';
import _t from 'counterpart';
import { UserRole } from '../../reducers/userReducer';
import { ExportDocument } from '../../components/ExportDocument';
import { MainTitle } from '../../components/MainTitle/MainTitle';
import { loadReferrals } from '../../services/BackendService';
import ReferralsTable from './ReferralsTable';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import ReferralsFilters from './ReferralsFilters';
import { clearReferralFilters } from '../../actions';
import { getFiltersCount } from './helpers';
import { useQueryClient } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';

const ReferralsPage = () => {
  const [showExportPopup, setShowExportPopup] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [_, setPage] = useQueryParam('page', NumberParam);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const onFilterChanged = () => {
    queryClient.invalidateQueries(['referrals']);
    setPage(1);
  };

  const token = useAppSelector((state) => state.user.token);
  const roles = useAppSelector((state) => state.user.roles);
  const filters = useAppSelector((state) => state.filters.referralFilters);
  const isLeader = roles.includes(UserRole.Leader);

  const xlsxLinkBase = `${process.env.REACT_APP_API_ROOT}/api/affiliates/customers/export/csv`;
  const xlsxCountBase = `${process.env.REACT_APP_API_ROOT}/api/affiliates/customers/export/count`;

  const filtersCount = getFiltersCount(filters);

  const clearFilters = () => {
    dispatch(clearReferralFilters());
  };

  return (
    <CContainer fluid className="c-main main-holder">
      <CRow>
        <CCol>
          <MainTitle>{_t('referrals.title')}</MainTitle>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <CCard className="card-overflow-visible">
            <CCardHeader className="pb-0">
              <div>
                <div className="filters-header">
                  <div className="filters-header-inline w-100">
                    <div className="filters-header-buttons float-left">
                      <CButton
                        className="filters-header-buttons-active"
                        onClick={() => setShowFilters(!showFilters)}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <span>{_t('global.filters')}</span>
                          {filtersCount > 0 && (
                            <div className="filters-header-buttons-active-inner">
                              {filtersCount}
                            </div>
                          )}
                          <div
                            className={`filters-header-buttons-active-image ${
                              showFilters ? 'rotated' : ''
                            }`}
                          />
                        </div>
                      </CButton>
                      <CButton
                        onClick={clearFilters}
                        className="filters-header-buttons-reset"
                      >
                        {_t('action.reset')}
                      </CButton>
                    </div>
                    <div className="float-right">
                      {isLeader && (
                        <CButton
                          className="mr-2 header-button-export"
                          onClick={() => setShowExportPopup(true)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <ReferralsFilters
                  show={showFilters}
                  onFilterChanged={onFilterChanged}
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <ReferralsTable queryKey="referrals" fetch={loadReferrals} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showExportPopup && (
        <ExportDocument
          xlsxLinkBase={xlsxLinkBase}
          xlsxCountBase={xlsxCountBase}
          onClose={() => setShowExportPopup(false)}
          showExportPopup={showExportPopup}
          token={token}
        />
      )}
    </CContainer>
  );
};

export default ReferralsPage;
