import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/AffiliateSidebar';
import { AffiliateContent } from '../components/AffiliateContent';
import { useQuery } from 'react-query';
import { greyLabelingEnabled } from '../services/BackendService';

const MainPage = () => {
	useQuery('greyLabelingEnabled', greyLabelingEnabled);

  return (
    <div className="c-app-c-default-layout">
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">
          <AffiliateContent />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
