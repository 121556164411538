import React from 'react';
import { CButtonClose, CFade, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { capitalize } from '../helpers';

interface IAlertProps {
  message: string | null;
  onCloseClicked: () => void;
  type: 'success' | 'error';
}

export const PopupAlert = ({ message, onCloseClicked, type }: IAlertProps) => (
  <CFade className={`popup-alert ${type === 'success' ? 'bg-success' : 'bg-danger'}`}>
    <CRow className="mx-0 px-3 py-2 align-items-center justify-content-between">
      <div className="d-flex flex-row">
        {type === 'error' && <CIcon name="cil-warning" size="xl" className="mx-2" />}
        {type === 'success' && <CIcon name="cil-check-circle" size="xl" className="mx-2" />}
        <p className="mb-0">
          {type === 'error' && _t('global.error-occured')}
          {type === 'success' && capitalize(`${_t('global.success')}:`)}
          &nbsp;
          {message}
        </p>
      </div>
      <CButtonClose onClick={onCloseClicked} className="p-1" style={{ color: 'white', opacity: 1 }} />
    </CRow>
  </CFade>
);
