import { CButton, CSwitch } from '@coreui/react';
import React, { useState } from 'react';
import ImageCropper from './ImageCropper';
import ImageUploader from './ImageUploader';
import _t from 'counterpart';

interface IProps {
  savedFile: string | null;
  setFile: (url: string | null) => void;
  minWidth: number;
  minHeight: number;
}

const LogoUploader = ({ savedFile, setFile, minHeight, minWidth }: IProps) => {
  const [isCropping, setIsCropping] = useState<boolean>();
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [imageNeedsInitialCrop, setImageNotSquare] = useState<boolean>(false);

  const storeImage = (url: string) => {
    setFile(url);
  };

  const removeImage = () => {
    setFile(null);
    setDarkMode(false);
  };

  const onImageCropped = (file: string) => {
    setFile(file);
    setIsCropping(false);
  };

  const enterCroppingMode = () => {
    setIsCropping(true);
  };

  const exitCroppingMode = () => {
    setIsCropping(false);
    if (imageNeedsInitialCrop) {
      removeImage();
    }
  };

  const toggleDarkModle = () => {
    setDarkMode(!darkMode);
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    console.log('on image loaded');
    if (image.width !== image.height && minWidth / minHeight === 1) {
      setImageNotSquare(true);
      setIsCropping(true);
    }
  }

  return (
    <>
      {!isCropping && (
        <ImageUploader
          allowMultipleFiles={false}
          addLogo={storeImage}
          removeLogo={removeImage}
          file={savedFile}
          darkMode={darkMode}
          onImageLoaded={onImageLoaded}
        />
      )}
      {savedFile && !isCropping && (
        <>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <span>{_t('my-brand.light-dark-preview')}</span>
            <CSwitch
              color="primary"
              variant="3d"
              className="cswitch-light-mode"
              checked={darkMode}
              onChange={toggleDarkModle}
            />
          </div>
          <CButton
            color="primary"
            className="mt-2 w-100"
            onClick={enterCroppingMode}
          >
            {_t('global.crop')}
          </CButton>
        </>
      )}

      {isCropping && savedFile !== null && (
        <ImageCropper
          url={savedFile}
          onSave={onImageCropped}
          onExit={exitCroppingMode}
          minHeight={minHeight}
          minWidth={minWidth}
        />
      )}
    </>
  );
};

export default LogoUploader;
