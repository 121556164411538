import React from 'react';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';

const Loading = () => {
  return (
    <div className="d-flex flex-grow-1 justify-content-center align-items-center flex-column">
      <CIcon name="hourglass" size="3xl" style={{ stroke: 'black' }} className="animation-rotate-center" />
      <h4 className="mt-4">{_t('global.loading')}</h4>
    </div>
  );
};

export default Loading;
