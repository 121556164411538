import { fork } from 'redux-saga/effects';
import userSaga from './userSaga';
import coreSaga from './coreSaga';
import notificationsSaga from './notificationsSaga';

function* rootSaga() {
  yield fork(userSaga);
  yield fork(coreSaga);
  yield fork(notificationsSaga);
}

export default rootSaga;
