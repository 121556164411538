import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppState } from './reducers';
import { hideErrorToast, hideSuccessToast, requestLoadUser } from './actions';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

import './scss/style.scss';
import { PopupAlert } from './components/PopupAlert';
import { useAppSelector } from './helpers/customHooks';
import FullscreenSpinner from "./components/FullscreenSpinner/FullscreenSpinner";

interface IAppProps {
  errorToast: string | null;
  successToast: string | null;
  hideErrorToast: () => void;
  hideSuccessToast: () => void;
  requestLoadUser: () => void;
  language: string;
  globalLoading: boolean;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = (({
  children,
  ...rest
}: any) => {
  const { token: isAuthenticated } = useAppSelector((state) => state.user);

  return (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { referrer: location.pathname },
          }}
        />
      ))}
    />
  );
});

const App = ({
  errorToast,
  hideErrorToast,
  hideSuccessToast,
  language,
  successToast,
  requestLoadUser,
  globalLoading
}: IAppProps) => {
  useEffect(() => {
    requestLoadUser();
  }, [requestLoadUser]);

  return (
    <div key={language}>
      {errorToast && <PopupAlert message={errorToast} onCloseClicked={hideErrorToast} type="error" />}
      {successToast && <PopupAlert message={successToast} onCloseClicked={hideSuccessToast} type="success" />}
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/">
          <MainPage />
        </PrivateRoute>
      </Switch>
      {globalLoading && <FullscreenSpinner />}
    </div>
  );
};

export default connect(
  (state: AppState) => ({
    errorToast: state.notifications.errorToast,
    successToast: state.notifications.successToast,
    language: state.core.language,
    globalLoading: state.core.globalLoading,
  }),
  { hideErrorToast, hideSuccessToast, requestLoadUser },
)(App);
